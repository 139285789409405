import classnames from "classnames";
import { IoIosArrowBack } from "react-icons/io";

export const RegitorrModal = ({
  ModalWrapper,
  title,
  children,
  close,
  isMiniWidth,
  asCancelIcon,
  onCancel,
  ID,
}) => {
  return (
    <ModalWrapper>
      <div
        className={classnames(ID, "registorModal-container", {
          "min-width": isMiniWidth,
        })}
      >
        <div className="px-2 d-flex ailgn-items-center registorModal-header">
          {asCancelIcon && (
            <div className="cancel-icon" title="Retour" onClick={onCancel}>
              <IoIosArrowBack style={{ width: 25, height: 25 }} />
            </div>
          )}
          <div className="title" style={{ fontSize: "28px" }}>
            {title}
          </div>
          <div className="closeModal" onClick={close}>
            &times;
          </div>
        </div>
        <hr className="divider" />
        <div className="py-3 registorModal-content">{children}</div>
      </div>

      <style jsx>{`
        .registorModal-container {
          background-color: #fff;
        }
        .registorModal-container:not(.min-width) {
          min-width: 500px;
        }
        .registorModal-container.min-width {
          width: 500px;
        }

        @media screen and (max-width: 700px) {
          .registorModal-container {
            //width: 90vw !important;
            width: 95vw !important;
          }
          .registorModal-container:not(.min-width) {
            min-width: auto;
          }
          .registorModal-container.account .title {
            font-size: 0.65em;
          }
        }

        .registorModal-header {
          color: #fff;
          text-align: center;
          font-size: 2rem;
        }

        .cancel-icon {
          color: #ccc;
          cursor: pointer;
          transition: 0.4s;
        }

        .cancel-icon:hover {
          color: #fff;
        }

        .title {
          color: #1647fb;
          flex: 1;
          //transform: scale(0.6);
        }

        .closeModal {
          color: #a8c4e5;
          padding: 0;
          margin-right: 10px;
          user-select: none;
          cursor: pointer;
          transition: color 0.4s ease;
        }

        .closeModal:hover {
          color: #fbc216;
        }

        .divider {
          margin: 0 auto;
          width: 100%;
        }
      `}</style>
    </ModalWrapper>
  );
};
