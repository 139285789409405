import styles from "./footer.module.scss";
import Link from "next/link";
import { appRoutes } from "../../configs/routes";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.sectionOne}>
          {/*<Link href="#">
            <a className="mr-2">A propos</a>
          </Link>
          <Link href="#">
            <a>Politique de confidentialité</a>
        </Link>*/}
          <span>
            Copyright &copy; {new Date().getFullYear()} | Regitorr . Tous droits
            réservés.
          </span>
        </div>

        {/*<ul>
          <li>
            <Link href={appRoutes.contacts}>
              <a>Contacts</a>
            </Link>
          </li>
        </ul>*/}
      </div>
    </footer>
  );
};

export default Footer;
