import styles from "./layouts.module.scss";
import { motion } from "framer-motion";
import Footer from "../footer/Footer";
import { useEffect } from "react";
import SEOTags from "../SEOTags";

const FrontOfficeLayout = ({
  pageTitle,
  pageDesc,
  pageUrl,
  pageImg,
  children,
  bgColor,
}) => {
  useEffect(() => {
    const Tawk_API = Tawk_API || {};
    // Tawk_LoadStart = new Date();

    const s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/61089852649e0a0a5ccf3290/1fc4p612t";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);

    return () => {
      s0.parentNode.removeChild(s1);
    };
  }, []);

  return (
    <motion.div
      exit={{ opacity: 0 }}
      className={styles.pageWrapper}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <SEOTags
        title={pageTitle}
        description={pageDesc}
        url={pageUrl}
        img={pageImg}
      />

      {children}

      <Footer />
    </motion.div>
  );
};

export default FrontOfficeLayout;
