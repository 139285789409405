import { RegitorrModal } from "./RegitorrModal";

const DisconnectedMoal = ({
  ModalWrapper,
  title,
  close,
  isOpen,
  onConfirm,
  submitting,
}) => {
  return (
    <RegitorrModal
      ModalWrapper={ModalWrapper}
      title={<small style={{ fontSize: "17px" }}>{title}</small>}
      close={close}
      isOpen={isOpen}
      isMiniWidth={true}
    >
      <p className="textConfirm modal-body">
        Sélectionner "Déconnexion" si vous êtes prêt à terminer votre session en
        cours.
      </p>
      <div className="modal-footer">
        <div className="py-4 parent d-flex justify-content-center px-sm-1">
          <button
            type="submit"
            disabled={submitting}
            className="border-warning btn btn-sm btn-outline-warning"
            onClick={onConfirm}
          >
            {submitting && (
              <div
                className="mr-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <span>Déconnexion</span>
          </button>

          <button
            type="submit"
            className="ml-2 text-white btn btn-sm btn-warning"
            onClick={close}
          >
            Fermer
          </button>
        </div>
      </div>

      <style jsx>{`
        /*.btn:hover {
                color: #fff !important;
            }*/
        .border-warning :hover {
          box-shadow: 0 1px 10px 1px rgba(34, 34, 34, 0.25);
          //color:#fff;
        }
        .parent {
          //margin-left: 15em;
          margin-top: -24px;
          margin-bottom: -37px;
        }
        .btn-warning:hover {
          background-color: #fbc216 !important;
          border-color: #fbc216 !important;
          //color: #fff !important;
          box-shadow: 0 1px 10px 1px rgba(34, 34, 34, 0.25);
        }
      `}</style>
    </RegitorrModal>
  );
};

export default DisconnectedMoal;
