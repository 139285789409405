import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const wrapperStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,  
};

const maskStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  backdropFilter: " blur(12px)",
  zIndex: 100000,

  /*backdropFilter: "blur(5px)",
  background: "rgba(82, 113, 130, 0.6)",*/
  //color: "#5f5f5f",
};

const containerStyle = {
  position: "relative",
  zIndex: 100001,
  //marginTop: -80,
};

const animationVariants = {
  visible: { opacity: 1, y: 0 /*, transition:{duration: 0.8}*/ },
  hidden: { opacity: 0, y: -100 },
};

const Modal = ({ children, isOpen = false, close }) => {
  if (!isOpen) return null;
  return createPortal(
    <div style={wrapperStyle}>
      <div style={maskStyle} /*onClick={close}*/ />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={animationVariants}
        style={containerStyle}
      >
        {children}
      </motion.div>
    </div>,
    document.querySelector("body")
  );
};

const UseModal = () => {
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen(true);
    //disableScroll.on();
    //document.querySelector("body").style.overflowY = "hidden";
  }, [setOpen]);

  const close = useCallback(() => {
    setOpen(false);
    //disableScroll.off();
    //document.querySelector("body").style.removeProperty("overflow-y");
  }, [setOpen]);

  const ModalWrapper = useCallback(
    ({ children }) => {
      return (
        <Modal isOpen={isOpen} close={close}>
          {children}
        </Modal>
      );
    },
    [isOpen, close]
  );
  return [ModalWrapper, open, close, isOpen];
};

export default UseModal;
