import React, { useState, Fragment } from "react";
import { useStoreActions, useStoreRehydrated, useStoreState } from "easy-peasy";
import Link from "next/link";
import { useRouter } from "next/router";
import { appRoutes } from "../../configs/routes";
import styles from "./navbar.module.scss";
import { FiShoppingCart } from "react-icons/fi";
import { BsBell, BsGrid } from "react-icons/bs";
import { BiPowerOff, BiUser } from "react-icons/bi";
import DisconnectedMoal from "../Modal/DisconnectedModal";
import UseModal from "../Modal/UseModal";
import ReactTooltip from "react-tooltip";
import cns from "classnames";

const Navbar = (props) => {
  const router = useRouter();
  const { currentUser } = useStoreState((state) => state.auth);
  const isUserLoggedIn = useStoreState((state) => state.auth.isUserLoggedIn);
  const { logoutUser, setIsLogoutAttempt } = useStoreActions(
    (state) => state.auth
  );

  const [submitting, setSubmitting] = useState(false);

  const { carts, allBookingsByUsers } = useStoreState(
    (state) => state.bookings
  );

  const [ConfModalWrapper, openConfModal, closeConfModal, isConfModalOpen] =
    UseModal();

  const logout = (e) => {
    setSubmitting(true);
    e.preventDefault();
    router.replace({ pathname: appRoutes.home, query: null }).then(() => {
      if(setIsLogoutAttempt)
      {
        closeConfModal()
      }
    });
    logoutUser();
  };

  const billboardStatusPending = () => {
    let totalsPending = 0;
    allBookingsByUsers.map((all) =>
      all.order
        .filter(
          (billboardFiltre) =>
            billboardFiltre.billboard.user === currentUser.id &&
            billboardFiltre.available === "En cours"
        )
        .map((_) => (totalsPending += 1))
    );
    return totalsPending;
  };

  const isStoreRehydrated = useStoreRehydrated();

  return (
    isStoreRehydrated && (
      <nav
        className={cns(styles.nav, {
          [`${styles.isUserLoggedIn}`]: isUserLoggedIn,
        })}
      >
        <div className={styles.navContent}>
          <div className={styles.logoWrapper}>
            <Link href={appRoutes.home}>
              <a className="d-inline-block">
                <img
                  src="/logo.png"
                  alt="Logo Registor"
                  // className={cns({
                  //   "d-none": isUserLoggedIn,
                  // })}
                />
                {/* <img
                src="/regitor2.png"
                alt="Logo Registor"
                className={cns({
                  "d-none": !isUserLoggedIn,
                })}
              /> */}
              </a>
            </Link>
          </div>
          <ul>
            {!isUserLoggedIn && (
              <Fragment>
                <li>
                  <Link href={appRoutes.register}>
                    <a className={cns(styles.registerBtn, "btn")}>
                      Inscription
                    </a>
                  </Link>
                </li>

                <li>
                  <Link href={appRoutes.login}>
                    <a className={cns(styles.loginBtn, "btn")}>Connexion</a>
                  </Link>
                </li>
              </Fragment>
            )}

            {isUserLoggedIn && (
              <Fragment>
                <li className={styles.widgetHeader}>
                  <Link href={appRoutes.panier}>
                    <a
                      data-tip="Mon panier"
                      className={cns(
                        `mr-2 border rounded-circle ${styles.rounded}`
                      )}
                    >
                      {" "}
                      <FiShoppingCart className={styles.icons} size="21px" />
                    </a>
                  </Link>
                  <span className={styles.notify}>{carts.length}</span>
                </li>

                <li className={styles.widgetHeader}>
                  <Link href={appRoutes.profile}>
                    <a
                      data-tip="Mon compte"
                      className={cns(
                        `mr-2 border rounded-circle ${styles.rounded}`
                      )}
                    >
                      <BiUser className={styles.icons} size="21px" />
                    </a>
                  </Link>
                </li>

                {currentUser?.profile &&
                  currentUser?.profile?.profile_type == "Regie" && (
                    <li className={styles.widgetHeader}>
                      <Link href={appRoutes.bookingBillboard}>
                        <a
                          data-tip="Notification"
                          className={cns(
                            `mr-2 border rounded-circle ${styles.rounded}`
                          )}
                        >
                          {" "}
                          <BsBell className={styles.icons} size="21px" />
                        </a>
                      </Link>
                      {billboardStatusPending() !== 0 && (
                        <span className={styles.notify}>
                          {billboardStatusPending()}
                        </span>
                      )}
                    </li>
                  )}

                {currentUser?.profile &&
                  currentUser?.profile?.profile_type == "Regie" && (
                    <li className={styles.widgetHeader}>
                      <Link href={appRoutes.homeBackend}>
                        <a
                          data-tip="Administration"
                          className={cns(
                            `mr-2 border rounded-circle ${styles.rounded}`
                          )}
                        >
                          <BsGrid className={styles.icons} size="21px" />
                        </a>
                      </Link>
                    </li>
                  )}

                {currentUser?.profile &&
                  currentUser?.profile?.profile_type == "Admin" && (
                    <li className={styles.widgetHeader}>
                      <Link href={appRoutes.homeAdmin}>
                        <a
                          data-tip="Administration"
                          className={cns(
                            `mr-2 border rounded-circle ${styles.rounded}`
                          )}
                        >
                          <BsGrid className={styles.icons} size="21px" />
                        </a>
                      </Link>
                    </li>
                  )}

                <li className={styles.widgetHeader}>
                  <a
                    href="#Déconnexion"
                    onClick={openConfModal}
                    data-tip="Déconnexion"
                    className={cns(`border rounded-circle ${styles.rounded}`)}
                  >
                    <BiPowerOff className={styles.icons} size="21px" />
                  </a>
                </li>
              </Fragment>
            )}
          </ul>

          <ReactTooltip place="top" type="info" effect="solid" />

          <DisconnectedMoal
            ModalWrapper={ConfModalWrapper}
            title="Prêt à partir ?"
            close={closeConfModal}
            isOpen={isConfModalOpen}
            onConfirm={logout}
            submitting={submitting}
          />
        </div>
      </nav>
    )
  );
};

export default Navbar;
